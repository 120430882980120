import { JSONSchema7 } from 'json-schema';
import _ from 'lodash';
import { useMemo } from 'react';

import { objectDotToKey } from '../../utils';

/* this helper function iterates over a JSON schema to extract certain default values associated with enums */

const helper = (
  properties: JSONSchema7['properties'],
  defaultValues: { [key: string]: unknown },
  prefix: string
) =>
  /* iterate over object */
  _.forIn(properties, (val, key) => {
    /* assert value as a JSONSchema */
    const value = val as JSONSchema7;

    /* get the path by adding the prefix to the key */
    const path = prefix + key;

    /* if the value has an `enum` array, and the array includes `null`, and the property doesn't already have a default value, add null as the default value for that property */
    if (
      value.enum &&
      value.enum.includes(null) &&
      _.get(defaultValues, path) === undefined
    ) {
      _.set(defaultValues, path, null);
    }

    /* if the value corresponds to an object with properties, run the function recursively */
    if (value.properties) {
      helper(value.properties, defaultValues, path + '.');
    }
  });

/* `getDefaultValues()` gets a `defaultValues` object and returns a copy after adding certain values as extracted by a helper function from the schema */

export const getDefaultValues = (
  schema: JSONSchema7,
  defaultValues: { [key: string]: unknown }
): { [key: string]: unknown } => {
  if (!schema) {
    return schema;
  }

  /* declare empty object to hold the new default values */
  let newDefaultValues = {};

  /* run helper function */
  helper(schema.properties, newDefaultValues, '');

  /* merge original default values with the new ones (make sure to put the original `defaultValues` last so they don't get overwritten) */
  newDefaultValues = _.merge(newDefaultValues, objectDotToKey(defaultValues));

  return newDefaultValues;
};

/* `useLabel()` returns a memoized label for a field */
export const useDefaultValues = (
  schema: JSONSchema7,
  defaultValues: { [key: string]: unknown }
): { [key: string]: unknown } => {
  return useMemo(
    () => getDefaultValues(schema, defaultValues),
    [schema, defaultValues]
  );
};
