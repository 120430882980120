import { PayloadAction, createSlice, AnyAction } from '@reduxjs/toolkit';
import { Draft } from 'immer';

import { User, AdUser, userApi } from '.';

export const initialState: User = {
  status: 'NONE',
};

// define reducers
const handleLogout = (state: Draft<User>) => {
  state.status = 'NONE';
  delete state.adUser;
  delete state.error;
};

// define extra reducers (https://redux-toolkit.js.org/api/createSlice#extrareducers)
const onPending = (state: Draft<User>) => {
  state.status = 'PENDING';
};

const onAdRejected = (state: Draft<User>, action: AnyAction) => {
  if (action.error.name !== 'ConditionError') {
    state.status = 'FAILED';
    delete state.adUser;
  }
};

const onAdUserInfo = (
  state: Draft<User>,
  action: PayloadAction<AdUser | undefined>
) => {
  if (action?.payload?.clientPrincipal) {
    state.status = 'OK';
    state.adUser = action.payload;
  } else {
    state.status = 'NONE';
    delete state.adUser;
  }
};

// create user slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: handleLogout,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(userApi.endpoints.status.matchFulfilled, onAdUserInfo)
      .addMatcher(userApi.endpoints.status.matchRejected, onAdRejected)
      .addMatcher(userApi.endpoints.status.matchPending, onPending);
  },
});

export const { logout } = userSlice.actions;

export const userReducer = userSlice.reducer;
