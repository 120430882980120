import { BodyText } from '@vaisala/rockhopper-components';
import { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IsbObject, TemplateDto } from '../../schema';
import {
  useEditTemplateMutation,
  useGetTemplateByTypeAndIdentifierQuery,
} from '../../state/api/api';
import { extractFieldInput } from '../../utils/templateSchemaUtil';
import { Attention } from '../Attention/Attention';
import { CenteredSpinner } from '../CenteredSpinner/CenteredSpinner';
import {
  TemplateField,
  TemplateFieldFieldName,
  TemplateForm,
} from './TemplateForm';

export const TemplateFormEdit = (): ReactElement => {
  const history = useHistory();
  const { type, identifier } = useParams<{
    type: IsbObject;
    identifier: string;
  }>();
  const [templateData, setTemplateData] = useState<
    TemplateField[] | undefined
  >();
  const [hasErrors, setHasErrors] = useState(false);

  const { data: templateRaw } = useGetTemplateByTypeAndIdentifierQuery({
    type,
    identifier,
  });

  const [editTemplate] = useEditTemplateMutation();

  useEffect(() => {
    if (!templateRaw) return;

    const fields = extractFieldInput(templateRaw.templateString).map(
      ({ fieldName, fieldType }) => {
        const [, { en: fieldLabel }] = Object.entries(
          templateRaw.translations as {
            [key in string]: { en: string };
          }
        ).find(([key]) => key === fieldName) ?? [null, { en: '' }];

        const [, { weight }] = Object.entries(
          templateRaw.weights as {
            [key in string]: { weight: number };
          }
        ).find(([key]) => key === fieldName) ?? [null, { weight: NaN }];

        return {
          name: fieldName,
          id: Math.random().toString().substring(2),
          fields: {
            [TemplateFieldFieldName.fieldName]: fieldName,
            [TemplateFieldFieldName.labelName]: fieldLabel,
            [TemplateFieldFieldName.fieldType]: fieldType,
          },
          weight,
        };
      }
    );

    setTemplateData(fields);
  }, [templateRaw]);

  const handleSubmit = async (template: TemplateDto) => {
    try {
      const updatedTemplate: TemplateDto = {
        ...template,
        id: templateRaw?.id,
        fixedValues: {
          productCode: templateRaw?.fixedValues?.productCode,
          productName: templateRaw?.fixedValues?.productName,
          siteType: templateRaw?.fixedValues?.siteType,
        },
      };

      await editTemplate({
        id: templateRaw?.id ?? '',
        data: updatedTemplate,
      });
      setHasErrors(false);
      history.push(`/templates?templateType=${template.type}`);
    } catch (error) {
      setHasErrors(true);
      console.error(error);
    }
  };

  if (!templateData) return <CenteredSpinner />;

  return (
    <>
      {hasErrors && (
        <BodyText
          style={{ margin: '0 auto 8px', maxWidth: '1000px', width: '100%' }}
        >
          <Attention mode="box">
            ERROR: Something went wrong. Please try again later.
          </Attention>
        </BodyText>
      )}
      <TemplateForm
        onSubmit={handleSubmit}
        templateFields={templateData}
        fixedValues={{
          productCode: templateRaw?.fixedValues?.productCode,
          productName: templateRaw?.fixedValues?.productName,
          siteType: templateRaw?.fixedValues?.siteType,
        }}
        templateType={templateRaw?.type}
        isEdit
      />
    </>
  );
};
