import { CSSProperties, ReactElement, ReactNode } from 'react';

import styles from './VisuallyHidden.module.scss';

/* <VisuallyHidden> hides content visually while keeping it available to assistive technologies (such as screen readers). the typical use case is when we need to provide a text alternative for an element that doesn't support the `alt` attribute. for example:

  <Button>
    <Icon name={VaiIcon.Edit} />
    <VisuallyHidden>Edit</VisuallyHidden>
  </Button>

in the example above, we could have used ARIA-based labelling (`aria-label` or `aria-labelledby`). however, ARIA labelling is notorious for bugs in multilingual situations, and visually hidden text delivers a more consistent and robust experience. more info: https://uxdesign.cc/the-troubled-state-of-screen-readers-in-multilingual-situations-f6a9da4ecdf3

if you put a focusable element inside <VisuallyHidden>, the element becomes visible as an overlay when it receives focus. for example:

  <VisuallyHidden>
    <Link to="#main">Skip to main content</Link>
  </VisuallyHidden>

the `style` prop only applies when the element is both focusable and in focus:

  <VisuallyHidden style={{ backgroundColor: 'red', padding: 10 }}>
    <Link to="#main">Skip to main content</Link>
  </VisuallyHidden>
*/

type VisuallyHiddenProps = {
  children: ReactNode;
  style?: Omit<CSSProperties, 'position'>;
};

export const VisuallyHidden = (props: VisuallyHiddenProps): ReactElement => {
  const { children, style } = props;

  return (
    <span className={styles.VisuallyHiddenOuter}>
      <span className={styles.VisuallyHiddenInner} style={style}>
        {children}
      </span>
    </span>
  );
};
