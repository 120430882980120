import { JSONSchema7 } from 'json-schema';
import _ from 'lodash';
import { DATE_PATTERN, DATE_TIME_PATTERN } from '..';

/* this helper function iterates over the properties of a JSON schema to remove `date-time` formats and add a regex pattern instead. this is needed because the library we use to convert JSON schemas to Yup schemas uses an outdated regex.
 */

const helper = (properties: JSONSchema7['properties']) =>
  /* iterate over object */
  _.forIn(properties, (val, key) => {
    /* assert value as a JSONSchema */
    const value = val as JSONSchema7;

    /* if the value has a format, and the format is `date-time` */
    if (value.format === 'date-time') {
      /* set format to undefined */
      value.format = undefined;

      /* set pattern */
      value.pattern = DATE_TIME_PATTERN;
    }

    /* if the value has a format, and the format is `date` */
    if (value.format === 'date') {
      /* set format to undefined */
      value.format = undefined;

      /* set pattern */
      value.pattern = DATE_PATTERN;
    }

    /* if the value corresponds to an object with properties, run the function recursively */
    if (value.properties) {
      helper(value.properties);
    }
  });

/* `schemaSetDateTimePattern()` copies a JSON schema, modifies it using the helper function above, and returns the modified version */

export const schemaSetDateTimePattern = (schema: JSONSchema7): JSONSchema7 => {
  if (!schema) {
    return schema;
  }

  /* make recursive copy of schema */
  const newSchema = _.cloneDeep(schema);

  /* run helper function */
  helper(newSchema.properties);

  return newSchema;
};
