import { JSONSchema7 } from 'json-schema';
import _ from 'lodash';

/* this helper function iterates over the properties of a JSON schema to add `null` as an option to any optional enum field. `null` is to be interpreted as "none" or "none of the above".

here's why we need a special "none of the above" value in general. take the following schema:

const schema: JSONSchema7 = {
  type: 'object',
  properties: {
    id: { type: 'string', readOnly: true },
    name: { type: 'string' },
    direction: {
      type: 'string',
      enum: ['North', 'East', 'South', 'West'],
    },
  },
  required: ['id', 'name'],
};

when we convert that to a Yup schema for form validation, the library we use for that conversion makes it so that only the values 'North', 'East', 'South' and 'West' will be accepted. that means it won't accept `undefined` as a value, which is the default value for unset items in our forms.

JSON schemas don't support `undefined` as a value, so we're using `null` instead. we could also use the empty string, but `null` is more versatile in case the enum contains numbers, booleans, etc.
*/

const helper = (properties: JSONSchema7['properties'], required: string[]) =>
  /* iterate over object */
  _.forIn(properties, (val, key) => {
    /* assert value as a JSONSchema */
    const value = val as JSONSchema7;

    /* if the value is not required, is enumerated, and does not already include `null` */
    if (
      !required.includes(key) &&
      !value.required &&
      value.enum &&
      !value.enum.includes(null)
    ) {
      /* add `null` to the `enum` array */
      value.enum = [null, ...value.enum];

      /* add the string 'null' as a value in `type` */
      value.type = _.isArray(value.type)
        ? ['null', ...value.type]
        : value.type
        ? ['null', value.type]
        : 'null';
    }

    /* if the value corresponds to an object with properties, run the function recursively */
    if (value.properties) {
      helper(value.properties, value.required || []);
    }
  });

/* `schemaSetOptionalEnum()` copies a JSON schema, modifies it using the helper function above, and returns the modified version */

export const schemaSetOptionalEnum = (schema: JSONSchema7): JSONSchema7 => {
  if (!schema) {
    return schema;
  }

  /* make recursive copy of schema */
  const newSchema = _.cloneDeep(schema);

  /* run helper function */
  helper(newSchema.properties, newSchema.required || []);

  return newSchema;
};
