import { BodyText } from '@vaisala/rockhopper-components';
import { ReactElement, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TemplateDto } from '../../schema';
import { useCreateTemplateMutation } from '../../state/api/api';
import { Attention } from '../Attention/Attention';
import { TemplateForm } from './TemplateForm';

export const TemplateFormCreate = (): ReactElement => {
  const history = useHistory();
  const [hasErrors, setHasErrors] = useState(false);
  const [createTemplate] = useCreateTemplateMutation();

  const { search } = useLocation();
  const templateTypeQueryValue =
    search
      .split('?templateType=')
      .filter((e) => e)
      .pop() ?? undefined;

  const handleSubmit = async (template: TemplateDto) => {
    try {
      if (template.type === 'asset') {
        delete template.fixedValues?.siteType;
      }

      if (template.type === 'site') {
        delete template.fixedValues?.productCode;
        delete template.fixedValues?.productName;
      }

      await createTemplate(template);
      setHasErrors(false);
      history.push(`/templates?templateType=${template.type}`);
    } catch (error) {
      setHasErrors(true);
      console.error(error);
    }
  };

  return (
    <>
      {hasErrors && (
        <BodyText
          style={{ margin: '0 auto 8px', maxWidth: '1000px', width: '100%' }}
        >
          <Attention mode="box">
            ERROR: Something went wrong. Please try again later.
          </Attention>
        </BodyText>
      )}
      <TemplateForm
        onSubmit={handleSubmit}
        templateType={templateTypeQueryValue}
      />
    </>
  );
};
