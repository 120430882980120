import { ReactElement } from 'react';
import { format } from 'date-fns';
import { TIME_FORMAT } from '../../utils/datetime';
import { Attention } from '../Attention/Attention';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { ExportSiteToSapResponseDto } from '../../schema/_typescript/models/ExportSiteToSapResponseDto';

import styles from './ExportResult.module.scss';

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

function getError(error?: FetchBaseQueryError | SerializedError) {
  if (!error) {
    return 'Unknown error';
  }
  if (isFetchBaseQueryError(error)) {
    switch (error.status) {
      case 'FETCH_ERROR':
        return 'A network error or ISB backend is unreachable. Try to export later.';
      case 'PARSING_ERROR':
        return 'Failed to parse ISB response.';
      case 'CUSTOM_ERROR':
        return error.error;
      default:
        return `${error.status} ${JSON.stringify(error.data)}`;
    }
  }
  return `ISB frontend error. ${error.code || ''} ${error.name || ''} ${
    error.message || ''
  }`.trim();
}

function getTime(fulfilledTimeStamp?: number) {
  const dateTime = getDateTime(fulfilledTimeStamp);
  return format(dateTime, TIME_FORMAT);
}
function getDateTime(fulfilledTimeStamp?: number): Date {
  if (fulfilledTimeStamp) {
    return new Date(fulfilledTimeStamp);
  }
  return new Date();
}

function buildMessage(isbResponse?: ExportSiteToSapResponseDto) {
  if (!isbResponse) return '';
  if (isbResponse.isExported) {
    if (isbResponse.isIsbPatchedWithSapId) {
      return 'Exported';
    }
    return 'Exported, but ISB did not store SAP id. Please report this case to ISB support!';
  }
  return `SAP error: ${isbResponse.exportError}`;
}

function getMessageType(data?: ExportSiteToSapResponseDto) {
  if (!data) return 'info';
  if (!data.isExported) return 'failure';
  if (!data.isIsbPatchedWithSapId) return 'warning';
  return 'success';
}

export const ExportResult = ({
  isError,
  isSuccess,
  timestamp,
  error,
  data,
}: {
  isError: boolean;
  isSuccess: boolean;
  timestamp?: number;
  error?: FetchBaseQueryError | SerializedError;
  data?: ExportSiteToSapResponseDto;
}): ReactElement => {
  const time = getTime(timestamp);
  return (
    <>
      <span className={styles.SAPExportTimeSpan}>
        {(isError || isSuccess) && time}
      </span>
      {isError && (
        <Attention mode="icon" type="failure">
          {getError(error)}
        </Attention>
      )}
      {isSuccess && (
        <Attention mode="icon" type={getMessageType(data)}>
          {buildMessage(data)}
        </Attention>
      )}
    </>
  );
};
