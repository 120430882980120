import { ReactElement } from 'react';
import _ from 'lodash';
import { VaiSpacing } from '@vaisala/rockhopper-design-tokens';

import { HierarchyDto } from '../../schema';

type ObjectReportHierarchyProps = {
  data: HierarchyDto;
};

export const ObjectReportHierarchy = ({
  data,
}: ObjectReportHierarchyProps): ReactElement => {
  const { type, name, children } = data;

  return (
    <ul style={{ listStyle: 'disc' }}>
      <li key={data.id} style={{ marginBlock: VaiSpacing.Xs }}>
        <span>
          {_.upperFirst(type)}: {name}
        </span>
        {children &&
          children.map((child) => (
            <ObjectReportHierarchy data={child} key={child.id} />
          ))}
      </li>
    </ul>
  );
};
