import { ReactElement } from 'react';
import {
  Button,
  ButtonType,
  Modal,
  Size,
} from '@vaisala/rockhopper-components';

import {
  IsbObjectMapping,
  IsbObjectWithoutCustomer,
  IsbTypesWithoutCustomerDto,
} from '../../schema';
import { useExpander } from '../../utils';
import { ObjectReportModal } from './ObjectReport.modal';
import styles from './ObjectReport.module.scss';

type ObjectReportProps = {
  data: IsbTypesWithoutCustomerDto;
  type: IsbObjectWithoutCustomer;
};

export const ObjectReport = ({
  data,
  type,
}: ObjectReportProps): ReactElement => {
  const { expanded, setExpanded } = useExpander();

  const handleButtonClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className={styles.ObjectReport}>
        <Button
          data-ta="ObjectReport-button"
          buttonType={ButtonType.Primary}
          onClick={handleButtonClick}
        >
          Generate report
        </Button>
      </div>
      <Modal
        width={Size.L}
        trapFocus={true}
        showCloseIcon
        isOpen={expanded}
        onDismiss={() => setExpanded(false)}
      >
        <ObjectReportModal data={data} type={IsbObjectMapping[type]} />
      </Modal>
    </>
  );
};
