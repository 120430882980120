import {
  Button,
  ButtonType,
  Form,
  Heading,
  InputField,
  Paper,
  RadioButton,
  RadioGroup,
} from '@vaisala/rockhopper-components';
import { useHistory } from 'react-router-dom';
import { SmartRenderStringOrNumber } from '../SmartRender/SmartRender';
import { SubmitHandler, UseFormHandleSubmit, FieldValues, UseFormRegister } from 'react-hook-form';
import { capitalize } from '../../utils/capitalize/capitalize';

import styles from './BaseFieldsSection.module.scss';
import { ValidationRule } from 'react-hook-form';

interface Parameters {
  productCode: string | undefined;
  productName: string | undefined;
  isEdit: boolean | undefined; // are we editing a template or creating new one
  templateType: 'asset' | 'site';
  siteType?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: { [x: string]: any };
  register: UseFormRegister<FieldValues>,
  handleSubmit: SubmitHandler<Record<string, string>>, // custom form handler
  onFormSubmit: UseFormHandleSubmit<FieldValues, undefined>, // provided by react-hook-form
  handleRadioButtonChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
}

const productCodePattern = {
  value: /^[a-zA-Z0-9]{0,64}$/,
  message:
    'Please only use a-z, A-Z and/or 0-9. Field length should be maximum of 64 characters. Spaces are not allowed.',
};
const productNamePattern = {
  value: /^.{1,64}$/,
  message: 'Field length should be maximum of 64 characters.',
};

function formItem(
  label: string,
  errorMsg: string | undefined,
  fieldValue: string | undefined,
  fieldName: string,
  dataTa: string,
  pattern: ValidationRule<RegExp>,
  register: UseFormRegister<FieldValues>,
) {
  return (
    <Form.Item
    label={label}
    required
    errors={errorMsg}
    >
      {fieldValue ? (
        <SmartRenderStringOrNumber>
          {fieldValue}
        </SmartRenderStringOrNumber>
      ) : (
        <InputField
          {...register(fieldName, {
            required: true,
            pattern: pattern,
          })}
          dataTa={dataTa}
        />
      )}
    </Form.Item>
  );
}

export function BaseFieldsSection({
  productCode,
  productName,
  isEdit,
  templateType, // templateTypeValue ?? templateType
  siteType,
  errors = {},
  register,
  handleSubmit,
  onFormSubmit,
  handleRadioButtonChange,
}: Parameters) {
  const history = useHistory();

  return (
    <Paper>
      <header className={styles.Header}>
        <Heading level={2} data-ta="TemplateFormMainHeading">
          {isEdit ? 'Update template' : 'Create template'}
        </Heading>
        <div>
          <Button buttonType={ButtonType.Secondary} onClick={() => history.push("/templates")}> Close </Button>
          <Button
            type="submit"
            buttonType={ButtonType.Primary}
            onClick={onFormSubmit(handleSubmit)}
            dataTa="TemplateFormSaveButton"
          >
            Save
          </Button>
        </div>
      </header>
      <section className={styles.BaseFields}>
        {templateType === 'site' && (
          formItem('Site type', errors?.siteType?.message as string, siteType, 'siteType', 'siteTypeInput', productCodePattern, register)
        )}
        { templateType === 'asset' && (
          <>
            {formItem('Product code', errors?.productCode?.message as string, productCode, 'productCode', 'productCodeInput', productCodePattern, register)}
            {formItem('Product name', errors?.productName?.message as string, productName, 'productName', 'productNameInput', productNamePattern, register)}
          </>
        )}
        <Form.Item label="Type" required>
          <RadioGroup
            className={styles.RadioGroup}
            value={templateType}
            onChange={handleRadioButtonChange}
            dataTa="templateTypeRadio"
          >
            {['asset', 'site'].map((type) => (
              <RadioButton
                key={type}
                value={type}
                disabled={!!templateType && isEdit}
              >
                {capitalize(type)}
              </RadioButton>
            ))}
          </RadioGroup>
        </Form.Item>
      </section>
    </Paper>  
  );
}