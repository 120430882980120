import {
  IsbObject,
  Weights,
  Translations,
  apiWeights,
  schemas,
  apiTranslations,
} from '../../schema';
import { JSONSchema7 } from 'json-schema';

export const getSmartFormDataByType = (
  type: IsbObject
): { weights: Weights; schema: JSONSchema7; translations: Translations } => {
  switch (type) {
    case 'asset':
      return {
        weights: apiWeights.AssetDto,
        schema: schemas.AssetDto,
        translations: apiTranslations.AssetDto,
      };
    case 'customer':
      return {
        weights: apiWeights.CustomerDto,
        schema: schemas.CustomerDto,
        translations: apiTranslations.CustomerDto,
      };
    case 'site':
      return {
        weights: apiWeights.SiteDto,
        schema: schemas.SiteDto,
        translations: apiTranslations.SiteDto,
      };
    case 'network':
      return {
        weights: apiWeights.NetworkDto,
        schema: schemas.NetworkDto,
        translations: apiTranslations.NetworkDto,
      };
  }
};
