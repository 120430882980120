import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Pagination } from '@vaisala/rockhopper-components';

/* <Paginator> renders a Rockhopper <Pagination> component, and also uses the `setItems` prop to tell the ancestor which data to show based on the current page */
/* TODO support backend pagination */

type PaginatorProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  itemsPerPage: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItems: Dispatch<SetStateAction<any[]>>;
};

export const Paginator = (props: PaginatorProps): ReactElement => {
  const { data, itemsPerPage, setItems } = props;
  const [page, setPage] = useState(1);

  /* on page change (aka when you activate any of the buttons in <Pagination>), update the `page` state */
  const handlePageChange = (newPage: number) => setPage(newPage);

  /* run this effect whenever anything in the dependency array changes, for example:
    - the `page` changes (see handlePageChange() above)
    - the number of `itemsPerPage` changes (not currently implemented)
    - the `data` changes (new data, changes in the order performed on the ancestor, etc)
    - the setItems() function changes (it shouldn't really)
  */
  useEffect(() => {
    /* create a slice of data corresponding to the current page */
    const start = itemsPerPage * (page - 1);
    const end = itemsPerPage * page;
    const items = data.slice(start, end);

    /* update the ancestor state */
    setItems(items);
  }, [page, itemsPerPage, data, setItems]);

  return (
    <Pagination
      itemsPerPage={itemsPerPage}
      totalItems={data.length}
      currentPage={page}
      onPageChange={handlePageChange}
    />
  );
};
