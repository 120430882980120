import { Button } from '@vaisala/rockhopper-components';
import { ReactElement, useState } from 'react';
import { AssetPresentationDto } from '../../schema';
import { useGetAssetTestStationDataQuery } from '../../state/api/api';
import { isFetchBaseQueryErrorIsb } from '../../utils';
import { CenteredSpinner } from '../CenteredSpinner/CenteredSpinner';

export type AssetTestStationDataProps = {
  id: AssetPresentationDto['id'];
};

export const AssetTestStationData = ({
  id,
}: AssetTestStationDataProps): ReactElement => {
  const [requestTestStationData, setRequestTestStationData] = useState(false);

  return (
    <>
      <Button
        onClick={() => setRequestTestStationData(true)}
        dataTa="AssetTestStationData-request-data-button"
        style={{ marginBottom: '16px' }}
      >
        Request test station data
      </Button>
      {requestTestStationData && <AssetTestStationDataFormattedJSON id={id} />}
    </>
  );
};

const AssetTestStationDataFormattedJSON = ({
  id,
}: AssetTestStationDataProps) => {
  const {
    isLoading,
    data: testStationData,
    isError,
    error,
  } = useGetAssetTestStationDataQuery(id);

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <section data-ta="AssetTestStationData-requested-data">
      <pre>
        <code data-ta="AssetTestStationData-formatted-data">
          {JSON.stringify(testStationData, null, 2)}
        </code>
      </pre>
      {isError && (
        <p data-ta="AssetTestStationData-error">
          {isFetchBaseQueryErrorIsb(error)
            ? error.data.error
            : 'Something went wrong while requesting test station data. Please try again later.'}
        </p>
      )}
    </section>
  );
};
