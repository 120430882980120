import _ from 'lodash';
import { JSONSchema7 } from 'json-schema';

import { IsbObjects } from '../../schema';
import { schemaSetReadOnly } from '..';

/* `schemaSetLinkSchema()` copies a JSON schema and transforms the `parent`, `children`, and `customers` properties of an schema so that we can use them in the UI */

export const schemaSetLinkSchema = (
  schema: JSONSchema7,
  allowedParents: Omit<IsbObjects, 'customers'>[],
  allowedChildren: Omit<IsbObjects, 'customers'>[]
): JSONSchema7 => {
  /* declare an array to hold the link properties */
  const properties: string[] = [];

  /* the object can have a parent if:
  - the `schema` has a `parent` property
  - the `allowedParents` array is not empty */
  schema?.properties?.parent &&
    !_.isEmpty(allowedParents) &&
    properties.push('parent');

  /* same for children */
  schema?.properties?.children &&
    !_.isEmpty(allowedChildren) &&
    properties.push('children');

  /* the object can have customers if the `schema` has a `customers` property */
  schema?.properties?.customers && properties.push('customers');

  /* if the object can have no parent, no children, and no customers, return early */
  if (_.isEmpty(properties)) {
    return {};
  }

  /* otherwise, copy the schema */
  let newSchema: JSONSchema7 = _.cloneDeep(schema);

  /* keep only the applicable properties */
  newSchema = _.pick(
    schema,
    ['type'].concat(properties.map((property) => `properties.${property}`))
  );

  /* make sure they're not readOnly, because we need to edit them in the UI (they are only listed as readOnly in the schema because they cannot be changed with a simple backend edit operation, since linking involves multiple objects) */
  newSchema = schemaSetReadOnly(newSchema, properties, false);

  /* make it so that they don't have required subproperties */
  /* IMPORTANT: don't get confused, we're not changing whether these properties are required or not; we're just making it so that their subproperties are not required. this is due to limitations with the tool we're using to turn JSON schemas into Yup schemas for form validation. if you don't do this step, the form will complain that e.g. "parent.id is required" when you `parent` itself is not required and you're just trying to leave that field blank */
  newSchema = _.omit(
    newSchema,
    properties.map((property) =>
      property === 'parent'
        ? `properties.${property}.required`
        : `properties.${property}.items.required`
    )
  );

  /* also copy the `required` array */
  let required = _.get(schema, 'required', []);

  /* filter `required` to keep only the applicable properties */
  required = required.filter((property) => properties.includes(property));

  /* if `required` is not empty, set the new `required`; otherwise, omit it */
  newSchema = !_.isEmpty(required)
    ? _.set(newSchema, 'required', required)
    : _.omit(newSchema, 'required');

  return newSchema;
};
