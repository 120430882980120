import { ReactElement } from 'react';
import _ from 'lodash';
import {
  BodyText,
  Button,
  ButtonType,
  Heading,
} from '@vaisala/rockhopper-components';

import { IsbObjects, IsbTypesWithoutCustomerDto } from '../../schema';
import { DATE_FORMAT, TIME_FORMAT } from '../../utils';
import { CenteredSpinner } from '../CenteredSpinner/CenteredSpinner';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';
import { SmartRenderDate } from '../SmartRender/SmartRender';
import { SpacedSeparator } from '../SpacedSeparator/SpacedSeparator';
import { ObjectReportHierarchy } from './ObjectReport.hierarchy';
import { ObjectReportTables } from './ObjectReport.tables';
import styles from './ObjectReport.module.scss';
import { useGetHierarchyByTypeAndIdQuery } from '../../state/api/api';

type ObjectReportModalProps = {
  data: IsbTypesWithoutCustomerDto;
  type: IsbObjects;
};

export const ObjectReportModal = ({
  data,
  type,
}: ObjectReportModalProps): ReactElement => {
  const { data: hierarchy, isLoading } = useGetHierarchyByTypeAndIdQuery({
    id: data.id,
    type,
  });

  const now = new Date().toISOString();

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <div className={styles.ObjectReportModal}>
      <Button
        data-ta="ObjectReport-print-button"
        buttonType={ButtonType.Primary}
        onClick={() => window.print()}
        className={styles.objectReport__printButton}
      >
        Print
      </Button>
      <div>
        <Heading
          style={{ textAlign: 'center' }}
          data-ta="ObjectReport-modal-heading"
        >
          {_.upperFirst(type)}: {data && data.name}
        </Heading>
        <div className={styles.ObjectReportModalHeader}>
          <div>
            <Heading level={2}>Timestamp</Heading>
            <BodyText style={{ margin: 0 }}>
              <VisuallyHidden>Date:</VisuallyHidden>
              <SmartRenderDate format={DATE_FORMAT}>{now}</SmartRenderDate>
            </BodyText>
            <BodyText style={{ margin: 0 }}>
              <VisuallyHidden>Time:</VisuallyHidden>
              <SmartRenderDate format={TIME_FORMAT}>{now}</SmartRenderDate>
            </BodyText>
          </div>
        </div>
      </div>
      {hierarchy ? (
        <>
          <SpacedSeparator />
          <Heading level={2}>Hierarchy</Heading>
          <ObjectReportHierarchy data={hierarchy} />
        </>
      ) : (
        <BodyText>No hierarchy to show.</BodyText>
      )}
      {hierarchy ? (
        <>
          <SpacedSeparator />
          <Heading level={2}>All data</Heading>
          <ObjectReportTables data={hierarchy} />
        </>
      ) : (
        <BodyText>No data to show.</BodyText>
      )}
    </div>
  );
};
