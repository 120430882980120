import { JSONSchema7 } from 'json-schema';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { TemplateDto, Translations, Weights } from '../../schema';
import { schemaSetReadOnly } from '../schemaSetReadOnly';

export const mergeTemplateData = (
  {
    schema: schemaOriginal,
    weights: weightsOriginal,
    translations: translationsOriginal,
  }: { weights: Weights; schema: JSONSchema7; translations: Translations },
  templateData: TemplateDto
): { weights: Weights; schema: JSONSchema7; translations: Translations } => {
  let newSchema = cloneDeep(schemaOriginal);
  const newWeights = cloneDeep(weightsOriginal);
  const newTranslations = cloneDeep(translationsOriginal);

  const { templateString, translations, weights, fixedValues } = templateData;

  const parsedTemplateString = JSON.parse(templateString);

  set(
    newSchema,
    'properties.templateData',
    parsedTemplateString.definitions.Template
  );

  newSchema = schemaSetReadOnly(
    newSchema,
    Object.keys(fixedValues || {}),
    true
  );

  newTranslations &&
    set(newTranslations, 'properties.templateData.properties', translations);

  newWeights && set(newWeights, 'properties.templateData', weights);

  return {
    schema: newSchema,
    weights: newWeights,
    translations: newTranslations,
  };
};
