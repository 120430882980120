import { IsbApiResponseDto } from '../../schema';
import { isFetchBaseQueryError } from '../isFetchBaseQueryError';

export type FetchBaseQueryIsbError = {
  status: number;
  data: IsbApiResponseDto & { error: string };
};

export function isFetchBaseQueryErrorIsb(
  error: unknown
): error is FetchBaseQueryIsbError {
  return (
    isFetchBaseQueryError(error) &&
    'data' in error &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'error' in error.data
  );
}
