import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: boolean = true;

export const navExpandedSlice = createSlice({
  name: 'navExpanded',
  initialState,
  reducers: {
    toggleExpanded: (state) => !state,
    setExpanded: (state, action: PayloadAction<boolean>) => action.payload,
  },
});

export const { toggleExpanded, setExpanded } = navExpandedSlice.actions;
export const navExpandedReducer = navExpandedSlice.reducer;
