import { JSONSchema7 } from 'json-schema';

export enum FieldTypes {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  BOOLEAN = 'boolean',
}

export interface CustomFieldInput {
  fieldType: FieldTypes;
  fieldName: string;
  labelName?: string;
}

export const extractFieldInput = (
  templateString: string
): CustomFieldInput[] => {
  let template: JSONSchema7;

  try {
    template = JSON.parse(templateString);
  } catch {
    throw new Error('Invalid JSON in template string: ' + templateString);
  }

  if (template?.definitions?.Template) {
    const schema = template.definitions.Template as JSONSchema7;

    if (schema.properties) {
      return Object.entries(
        schema.properties as { [key in string]: JSONSchema7 }
      ).map(([fieldName, fieldSchema]) => {
        return {
          fieldType: schemaTypeToFieldType(fieldSchema),
          fieldName,
        };
      });
    }
  }
  throw new Error('Invalid template string: ' + templateString);
};

export const createJsonSchema = (input: CustomFieldInput[]): string => {
  const seed: { [key: string]: JSONSchema7 } = {};

  const properties = input.reduce(
    (previous, current) => ({
      ...previous,
      [current.fieldName]: {
        ...fieldTypeToSchemaType(current.fieldType),
      },
    }),
    seed
  );

  return JSON.stringify(getSkeleton(properties));
};

const getSkeleton = (properties: {
  [key: string]: JSONSchema7;
}): JSONSchema7 => ({
  $schema: 'http://json-schema.org/draft-07/schema#',
  $ref: '#/definitions/Template',
  definitions: {
    Template: {
      type: 'object',
      properties,
      additionalProperties: false,
    },
  },
});

const fieldTypeToSchemaType = (fieldType: FieldTypes): Partial<JSONSchema7> => {
  switch (fieldType) {
    case FieldTypes.STRING:
      return {
        type: 'string',
      };
    case FieldTypes.NUMBER:
      return {
        type: 'number',
      };
    case FieldTypes.BOOLEAN:
      return {
        type: 'boolean',
      };
    case FieldTypes.DATE:
      return {
        type: 'string',
        format: 'date-time',
      };
    default: {
      throw new Error('Unsupported field type: ' + fieldType);
    }
  }
};

const schemaTypeToFieldType = (schemaType: JSONSchema7): FieldTypes => {
  const defaultCase = () => {
    throw new Error('Unsupported schema type: ' + JSON.stringify(schemaType));
  };

  switch (schemaType.type) {
    case 'boolean':
      return FieldTypes.BOOLEAN;
    case 'number':
      return FieldTypes.NUMBER;
    case 'string':
      if (schemaType.format === 'date-time') {
        return FieldTypes.DATE;
      } else if (!schemaType.format) {
        return FieldTypes.STRING;
      } else {
        return defaultCase();
      }
    // Unsupported format - falls through
    default:
      return defaultCase();
  }
};
