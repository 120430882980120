import { ReactElement, ReactNode, useState } from 'react';
import { NavTabs, Tab, Paper } from '@vaisala/rockhopper-components';
import { Link, useLocation } from 'react-router-dom';

/* <NavigationTabs> provides the third level of navigation (our <NavigationMenu> only has two because it's based on Rockhopper's <SimpleNavigation>) */

type NavigationTab = {
  content: ReactNode;
  slug: string;
  name: string;
};

type NavigationTabsProps = {
  tabs: NavigationTab[];
};

export const NavigationTabs = (props: NavigationTabsProps): ReactElement => {
  const { tabs } = props;

  /* get the current location */
  /* if the current path is "/a/b?tab=hierarchy", then:
      - pathname = "/a/b"
      - search = "?tab=hierarchy" */
  const { pathname, search } = useLocation();

  /* use state to keep track of the active tab's slug. the active tab is the one whose slug is in the `search` value. if no match is found, default to the first tab being active */
  const [active, setActive] = useState(
    tabs.find((tab) => `?tab=${tab.slug}` === search)?.slug || tabs[0].slug
  );

  return (
    <>
      {/* first render Rockhopper's <NavTabs> */}
      <NavTabs
        /* specify the active tab via de `selectedTabKey` property */
        selectedTabKey={active}
        /* when a new tab is selected, update the state */
        onSelectedTabChanged={(tabKey) => setActive(tabKey)}
      >
        {/* map over tabs to render Rockhopper <Tab> components */}
        {tabs.map(({ slug, name }) => (
          <Tab
            key={slug}
            dataTa={`NavigationTab`}
            tabKey={slug}
            title={
              <Link
                /* if there's no slug, the path is simply the `pathname`; otherwise, add the appropriate `search` string */
                to={!slug ? pathname : `${pathname}?tab=${slug}`}
              >
                {name}
              </Link>
            }
          />
        ))}
      </NavTabs>
      {/* then render Rockhopper's <Paper> */}
      <Paper>
        {/* render the content of the `tab` whose slug matches the one stored in the `active` state. if no match is found, render the content of the first tab */}
        {tabs.find((tab) => `?tab=${tab.slug}` === search)?.content ||
          tabs[0].content}
      </Paper>
    </>
  );
};
