import { Button } from '@vaisala/rockhopper-components';
import { ReactElement } from 'react';
import { useExportSiteToSapMutation } from '../../state/api/api';
import { ExportResult } from './ExportResult';
import { Spinner } from '@vaisala/rockhopper-components';

import styles from './SiteSAPExport.module.scss';

export const SiteSAPExport = ({ id }: { id: string }): ReactElement => {
  const [
    exportToSap,
    { isLoading, isError, isSuccess, fulfilledTimeStamp, error, data },
  ] = useExportSiteToSapMutation();

  return (
    <section
      className={styles.SAPExportContainer}
      data-ta="SAPExport-container"
    >
      <section>
        <Button
          onClick={() => exportToSap(id)}
          dataTa="SAPExport-start-export-button"
          disabled={isLoading}
        >
          Start SAP Export
        </Button>
      </section>
      <section className={styles.SAPExportStatus}>
        {isLoading && <Spinner dataTa="spinner" />}
        <ExportResult
          isError={isError}
          isSuccess={isSuccess}
          timestamp={fulfilledTimeStamp}
          error={error}
          data={data}
        />
      </section>
    </section>
  );
};
