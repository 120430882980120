import {
  Button,
  Form,
  Heading,
  Icon,
  InputField,
  Option,
  Paper,
  Select,
  Size,
} from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
import { capitalize } from '../../utils/capitalize/capitalize';
import { FieldTypes } from '../../utils/templateSchemaUtil';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';
import { TemplateField, TemplateFieldFieldName } from './TemplateForm';
import { FieldListItem } from './FieldListItem';
import styles from './TemplateForm.fields.module.scss';

interface TemplateFormFieldsProps {
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  templateFields?: TemplateField[];
  setTemplateFields: React.Dispatch<
    React.SetStateAction<TemplateField[] | undefined>
  >;
  onFieldUpdate(id: string): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: { [x: string]: any };
}

const getLabelByPropertyKey = (key: string) => {
  if (key === TemplateFieldFieldName.fieldName) return 'Field name';
  if (key === TemplateFieldFieldName.labelName) return 'Field label';
  if (key === TemplateFieldFieldName.fieldType) return 'Field type';

  return 'unknown field';
};

export const TemplateFormFields = ({
  register,
  unregister,
  setValue,
  templateFields,
  setTemplateFields,
  onFieldUpdate,
  errors = {},
  trigger,
  watch,
}: TemplateFormFieldsProps): ReactElement => {
  
  const [currentOpenId, setCurrentOpenId] = useState<string | undefined>();

  const handleRadioButtonChange = (currentId: string) => {
    setCurrentOpenId(currentId);
  };

  const handleDelete = (currentId: string) => {
    if (currentId === currentOpenId) {
      setCurrentOpenId(undefined);
    }

    setTemplateFields(templateFields?.filter(({ id }) => id !== currentId));
    unregister(currentId);
  };

  const handleCreate = useCallback(() => {
    const generatedId = Math.random().toString().substring(2);

    const getNewTemplateField = (templateFields?: TemplateField[]) => {
      const newItemIndex =
        Math.max(
          ...(templateFields?.length
            ? templateFields?.map(({ weight }) => weight)
            : [0])
        ) + 1 ?? 0;

      return {
        name: `Field ${newItemIndex}`,
        id: generatedId,
        weight: newItemIndex,
        fields: {
          [TemplateFieldFieldName.fieldName]: '',
          [TemplateFieldFieldName.labelName]: '',
          [TemplateFieldFieldName.fieldType]: FieldTypes.STRING,
        },
      };
    };

    setTemplateFields((oldTemplatesFields) => [
      ...(oldTemplatesFields ?? []),
      getNewTemplateField(oldTemplatesFields),
    ]);

    setCurrentOpenId(generatedId);

    trigger();
  }, [setTemplateFields, trigger]);

  const handleFieldWeightChange = (
    currentId: string,
    direction: 'up' | 'down'
  ) => {
    const templateToModify = templateFields?.find(({ id }) => id === currentId);
    const sorted = templateFields?.concat().sort(sortFieldsAsc);

    if (!sorted || !templateToModify) return;

    const otherItem =
      sorted[sorted?.indexOf(templateToModify) + (direction === 'up' ? -1 : 1)];

    if (!otherItem) return;

    const otherItemInWeigth = otherItem.weight;
    otherItem.weight = templateToModify.weight;
    templateToModify.weight = otherItemInWeigth;

    setValue(`${otherItem.id}.weight`, otherItem.weight, {
      shouldValidate: true,
    });

    setValue(`${currentId}.weight`, templateToModify.weight, {
      shouldValidate: true,
    });

    setTemplateFields((previousFields) =>
      previousFields
        ?.map((field) => (field.id !== otherItem.id ? field : otherItem))
        .map((field) =>
          field.id !== templateToModify.id ? field : templateToModify
        )
    );
  };

  useEffect(() => {
    if (currentOpenId) return;

    if (!templateFields) {
      handleCreate();
      return;
    }

    setCurrentOpenId(templateFields[0]?.id);
  }, [currentOpenId, handleCreate, templateFields]);

  const sortFieldsAsc = (a: TemplateField, b: TemplateField) =>
    a.weight - b.weight;

  return (
    <section className={styles.FieldsContainer}>
      <Paper>
        <header className={styles.Header}>
          <Heading level={3}>Template fields</Heading>
          <Button buttonSize={Size.S} onClick={handleCreate} data-ta="TemplateFormFieldsAddButton">
            <Icon name={VaiIcon.Plus} />
            <VisuallyHidden>Add new template field</VisuallyHidden>
          </Button>
        </header>
        <ol
          className={styles.FieldsList}
          data-ta="TemplateFormFieldsList"
        >
          {templateFields &&
            templateFields
              .concat()
              .sort(sortFieldsAsc)
              .map(({ name, id }, index) => {
                return <FieldListItem
                  key={id}
                  itemId={id}
                  itemName={name}
                  itemIndex={index}
                  isSelected={currentOpenId ? id === currentOpenId : index === 0}
                  itemsCount={templateFields.length}
                  handleRadioButtonChange={handleRadioButtonChange}
                  handleDelete={handleDelete}
                  handleFieldWeightChange={handleFieldWeightChange}
                  errors={errors}
                />
              })}
        </ol>
      </Paper>
      {currentOpenId &&
        templateFields?.map(({ id, fields, weight }) => (
          <Paper
            style={{ display: id === currentOpenId ? 'block' : 'none' }}
            key={id}
            data-ta="TemplateFormFieldsAside"
            id={id}
            data-currently-open={id === currentOpenId}
          >
            <header className={styles.Header}>
              <Heading level={3}> Enter details </Heading>
            </header>  
            <input
              type="hidden"
              {...register(`${id}.weight`, { value: weight })}
            />
            <div
              className={styles.AsideFormSection}
              data-ta="TemplateFormFieldsAsideFormSection"
            >
              {Object.entries(fields).map(([propertyKey, value]) => {
                const fieldErrorMessage =
                  'Please only use a-z, A-Z and/or 0-9. Field length should be maximum of 64 characters. Start with a lowercase letter. Spaces are not allowed.';

                const inputHandler = register(`${id}.${propertyKey}`, {
                  value,
                  required: true,
                  onBlur: () => onFieldUpdate(id),
                  pattern: {
                    value:
                      propertyKey === 'fieldName'
                        ? /^[a-z][a-zA-Z0-9]{0,64}$/
                        : /^.{1,64}$/,
                    message:
                      propertyKey === 'fieldName'
                        ? `${fieldErrorMessage}`
                        : 'Field length should be maximum of 64 characters.',
                  },
                });
                const currentValue = watch(`${id}.${propertyKey}`);

                return (
                  <Form.Item
                    label={getLabelByPropertyKey(propertyKey)}
                    required
                    key={`${propertyKey}-${id}`}
                    errors={errors?.[id]?.[propertyKey]?.message}
                    dataTa="TemplateFormFieldsAsideFormSectionFormItem"
                  >
                    {propertyKey === TemplateFieldFieldName.fieldType && (
                      <>
                        <Select
                          value={currentValue}
                          onChange={(value) => {
                            setValue(`${id}.${propertyKey}`, value, {
                              shouldValidate: true,
                            });
                          }}
                        >
                          {['string', 'number', 'date', 'boolean'].map(
                            (value) => (
                              <Option key={value} value={value}>
                                {capitalize(value)}
                              </Option>
                            )
                          )}
                        </Select>
                        {/* POC Implementation for enum in the future. */}
                        {currentValue === 'list' && <input></input>}
                      </>
                    )}
                    {propertyKey !== TemplateFieldFieldName.fieldType && (
                      <InputField {...inputHandler} />
                    )}
                  </Form.Item>
                );
              })}
            </div>
          </Paper>
        ))}
    </section>
  );
};
