import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { NavigationRouteHierarchy } from '@vaisala/rockhopper-components';

import { useUser } from '../../state/user';
import { AppRoute } from '../AppRouter/Routes';

/* `filterRoutes()` is a helper function that helps us remove routes that have `onNavigation = false`.

for example:
[
  {
    slug: 'a',
    path: '/a',
    onNavigation: true,
  },
  {
    slug: 'b',
    path: '/b',
    onNavigation: true,
    subroutes: [
      {
        slug: '1',
        path: '/b/1',
        onNavigation: true,
      },
      {
        slug: '2',
        path: '/b/2',
        onNavigation: true,
      },
      {
        slug: '3',
        path: '/b/3',
        onNavigation: false,
      },
    ]
  },
  {
    slug: 'c',
    path: '/c',
    onNavigation: true,
    subroutes: [
      {
        slug: '1',
        path: '/c/1',
        onNavigation: false,
      },
    ]
  },
]

becomes:
[
  {
    slug: 'a',
    path: '/a',
    onNavigation: true,
  },
  {
    slug: 'b',
    path: '/b',
    onNavigation: true,
    subroutes: [
      {
        slug: '1',
        path: '/b/1',
        onNavigation: true,
      },
      {
        slug: '2',
        path: '/b/2',
        onNavigation: true,
      },
    ]
  },
  {
    slug: 'c',
    path: '/c',
    onNavigation: true
  },
]
*/

const filterRoutes = (routes: AppRoute[], loggedIn: boolean): AppRoute[] => {
  /* start with the `routes` array */
  const filtered = routes
    /* use filter to keep a route only if:
    - it should be on the nav
    - it doesn't require auth
    - it does requires auth but the user is logged in
     */
    .filter(
      ({ onNavigation, needsAuth }) => onNavigation && (!needsAuth || loggedIn)
    )
    /* use map to... */
    .map((route) => {
      /* run `filterRoutes` recursively on the subroutes */
      const subroutes =
        route.subroutes && filterRoutes(route.subroutes, loggedIn);
      /* get a modified route object where `subroutes` is undefined if there's
      no subroutes to begin with, or none left after filtering */
      return {
        ...route,
        subroutes: subroutes && subroutes[0] ? subroutes : undefined,
      };
    });

  return filtered;
};

/* `createHierarchy()` is a helper function that builds a navigation hierarchy object as expected by the Rockhopper <SimpleNavigation> component.

for example:
[
  {
    slug: 'a',
    path: '/a',
  },
  {
    slug: 'b',
    path: '/b',
    subroutes: [
      {
        slug: '1',
        path: '/b/1',
      },
      {
        slug: '2',
        path: '/b/2',
      },
    ]
  },
  {
    slug: 'c',
    path: '/c',
  },
]

becomes:
{
  a: {
    item: <Link to="/a">a</Link>
  },
  b: {
    title: <Link to="/b">b</Link>,
    items: {
      1: <Link to="/b/1">c</Link>,
      2: <Link to="/b/2">d</Link>,
    }
  },
  c: {
    item: <Link to="/c">c</Link>
  },
}
*/

const createHierarchy = (
  routes: AppRoute[],
  maxLevels: number = 2,
  currentLevel: number = 0
): NavigationRouteHierarchy => {
  /* start with the `routes` array */
  const hierarchy = routes
    /* use reduce() to transform the array into an object */
    .reduce((object, { slug, path, name, subroutes }) => {
      /* if the route has no subroutes and we haven't yet reached the max level --> take the current object and add a key that looks like:
        a: {
          item: <Link to="/a">a</Link>
        },
      */
      if (!subroutes && currentLevel + 1 < maxLevels) {
        return {
          ...object,
          [slug]: {
            item: <Link to={path}>{name}</Link>,
          },
        };
      }
      /* otherwise, if the route has subroutes and we haven't yet reached the max level --> take the current object and add a key that looks like:
        b: {
          title: <Link to="/b">b</Link>,
          items: [...]
        }
      where [...] is the result of calling this function recursively, passing `subroutes` as the `routes` parameter and adding 1 to the current level
      */
      if (subroutes && currentLevel + 1 < maxLevels) {
        return {
          ...object,
          [slug]: {
            title: <Link to={path}>{name}</Link>,
            items: createHierarchy(subroutes, maxLevels, currentLevel + 1),
          },
        };
      }
      /* otherwise --> take the current object and add a key that looks like:
        1: <Link to="/b/1">c</Link>
      */
      return {
        ...object,
        [slug]: <Link to={path}>{name}</Link>,
      };
    }, {});

  return hierarchy;
};

/* `useNavigationHierarchy()` is a function that returns a memoized navigation hierarchy object */

export const useNavigationHierarchy = (
  routes: AppRoute[]
): NavigationRouteHierarchy => {
  /* get the login status */
  const { loggedIn } = useUser();

  /* memoize the array of filtered routes */
  const filtered = useMemo(
    () => filterRoutes(routes, loggedIn),
    [routes, loggedIn]
  );

  /* memoize the navigation hierarchy object */
  const hierarchy = useMemo(() => createHierarchy(filtered), [filtered]);

  return hierarchy;
};
