import { ReactElement } from 'react';
import { VaiSpacing } from '@vaisala/rockhopper-design-tokens';

import { getSmartFormDataByType } from '../../utils';
import { SmartTable } from '../SmartTable/SmartTable';
import { HierarchyDto, IsbObjectTree } from '../../schema';

type ObjectReportTablesProps = {
  data: HierarchyDto;
};

export const ObjectReportTables = ({
  data,
}: ObjectReportTablesProps): ReactElement | null => {
  const { weights, translations } = getSmartFormDataByType(data.type);

  const formatNodeToSmartTable = (object: HierarchyDto) => {
    if (!object) {
      return null;
    }

    const { type, children, ...rest } = object;

    const newChildren = children
      ? (children.filter((child) => child) as NonNullable<IsbObjectTree>[]).map(
          ({ id, type, name }) => ({ id, type, name })
        )
      : undefined;

    return {
      children: newChildren,
      ...rest,
    };
  };

  if (!data) return null;

  return (
    <div key={data.id}>
      <div style={{ marginBlockEnd: VaiSpacing.M }}>
        <SmartTable
          type={data.type}
          data={formatNodeToSmartTable(data) || {}}
          translations={translations}
          weights={weights}
        />
      </div>
      {data?.children &&
        data?.children.map((child) => (
          <ObjectReportTables data={child} key={child.id} />
        ))}
    </div>
  );
};
