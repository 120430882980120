import { ReactElement } from 'react';
import { Separator } from '@vaisala/rockhopper-components';
import { VaiSpacing } from '@vaisala/rockhopper-design-tokens';

type SpacedSeparatorProps = {
  spacing?: VaiSpacing;
};

export const SpacedSeparator = (props: SpacedSeparatorProps): ReactElement => {
  const { spacing = VaiSpacing.L } = props;

  return (
    <div style={{ marginBlock: spacing }}>
      <Separator />
    </div>
  );
};
