import { useAppSelector } from '../reduxHooks';

export type UserStatus = 'NONE' | 'PENDING' | 'OK' | 'FAILED';

export type AdClientPrincipal = {
  identityProvider: string;
  userId: string;
  userDetails: string;
  userRoles: string[];
};

export type AdUser = {
  clientPrincipal: AdClientPrincipal;
};

export type User = {
  status: UserStatus;
  adUser?: AdUser;
  error?: string;
};

export type UseUser = {
  loggedIn: boolean;
  status: UserStatus;
  adUser?: AdUser;
  error?: string;
};

export const useUser = (): UseUser => {
  const user = useAppSelector((state) => state?.user);
  return { loggedIn: user?.status === 'OK', ...user };
};
