import { useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { endpoints } from '../../state/api/api';
import { useAppDispatch } from '../../state/reduxHooks';
import { IsbObjects } from '../../schema';
import {
  appRoutes,
  AppRoute,
  AppRouteParams,
} from '../../components/AppRouter/Routes';

/* `flattenRoutes()` is a helper function that extracts any arrays that could be nested in the `subroutes` property

for example:
[
  {
    slug: 'a',
    path: '/a'
  },
  {
    slug: 'b',
    path: '/b',
    subroutes: [
      {
        slug: 'c',
        path: '/b/c'
      }
    ]
  }
] 

becomes:
[
  {
    slug: 'a',
    path: '/a'
  },
  {
    slug: 'b',
    path: '/b',
  },
  {
    slug: 'c',
    path: '/b/c'
  }
] 
*/

const flattenRoutes = (routes: AppRoute[]): AppRoute[] => {
  /* reduce the routes array. in every iteration... */
  const newRoutes = routes.reduce(
    (array, route) => [
      /* take the existing array */
      ...array,
      /* add the current route, setting its subroutes to undefined */
      { ...route, subroutes: undefined },
      /* if there are subroutes, call the function recursively  */
      ...(route.subroutes ? flattenRoutes(route.subroutes) : []),
    ],
    [] as AppRoute[]
  );
  return newRoutes;
};

/* `usePageTitle()` is a function that gets and memoizes the page title that matches the current page */

export const usePageTitle = (): string => {
  /* get the path of current location */
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();
  const [objectName, setObjectName] = useState('');

  /* memoize the page title */
  const title = useMemo(() => {
    /* flatten the appRoutes array */
    const flatRoutes = flattenRoutes(appRoutes);

    let type;
    let id;

    /* find an element whose path matches */
    const route = flatRoutes.find((route) => {
      const match = matchPath<AppRouteParams>(pathname, {
        path: route.path,
        exact: true,
      });
      /* if there's a match, save possible ID parameter */
      if (match) {
        type = match.path.split('/')[1] as IsbObjects;
        id = match.params.id || '';
      }
      return match;
    });

    if (type && id) {
      dispatch(
        endpoints.getByTypeAndId.initiate({
          type: type,
          id: id,
        })
      ).then((result) => {
        if (!result.data) return setObjectName('');

        return setObjectName(
          'name' in result.data
            ? result.data.name
            : `${result.data.serialNumber} (${result.data.productCode})`
        );
      });
    } else {
      setObjectName('');
    }

    return route && objectName
      ? `${route.name}: ${objectName}`
      : route
      ? `${route.name}`
      : 'ERROR: missing title';
  }, [pathname, dispatch, objectName]);

  return title;
};
