import { JSONSchema7 } from 'json-schema';
import _ from 'lodash';

import { objectToSchemaPath } from '../../utils';

/* `schemaSetReadOnly()` makes a copy of a JSON `schema`, modifies the properties whose keys were passed in the `modify` array, and returns the modified schema.

for example, take the following schema:

  const schema: JSONSchema7 = {
    type: 'object',
    properties: {
      id: { type: 'string', readOnly: true },
      name: { type: 'string' },
      location: {
        type: 'object',
        properties: {
          latitude: { type: 'number' },
          longitude: { type: 'number' },  
          altitude: { type: 'number' },
        },
      },
    },
    required: ['id', 'name'],
  };

and the following array:

  const modify = ['name', 'location.latitude']

running this function:

  const newSchema = schemaSetReadOnly(schema, modify)

will result in the following new schema:

  {
    type: 'object',
    properties: {
      id: { type: 'string', readOnly: true },
      name: { type: 'string', readOnly: true },
      location: {
        type: 'object',
        properties: {
          latitude: { type: 'number', readOnly: true },
          longitude: { type: 'number' },
          altitude: { type: 'number' },
        },
      },
    },
    required: ['id', 'name'],
  };

*/

export const schemaSetReadOnly = (
  schema: JSONSchema7,
  modify: string[],
  value: boolean
): JSONSchema7 => {
  if (!schema || !modify || _.isEmpty(modify)) {
    return schema;
  }

  /* make recursive copy of schema */
  const newSchema = _.cloneDeep(schema);

  /* transform the members of the `modify` array so that, for example:
    - name --> properties.name
    - location.latitude --> properties.location.properties.latitude
  */
  const newModify = modify.map((item) => objectToSchemaPath(item));

  /* for each path in the `newModify` array... */
  newModify.forEach(
    (path) =>
      /* if the property exists (e.g. if `properties.name` exits)... */
      _.get(newSchema, path) !== undefined &&
      /* modify its `readOnly` subproperty */
      _.set(newSchema, path + '.readOnly', value)
  );

  return newSchema;
};
