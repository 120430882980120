import { DevTool } from '@hookform/devtools';
import {
  Form,
  PageContainer,
} from '@vaisala/rockhopper-components';
import { ReactElement, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TemplateDto } from '../../schema';
import {
  createJsonSchema,
  CustomFieldInput,
  FieldTypes,
} from '../../utils/templateSchemaUtil';
import { BaseFieldsSection } from './BaseFieldsSection';
import { TemplateFormFields } from './TemplateForm.fields';
import styles from './TemplateForm.module.scss';

const development = process.env.NODE_ENV === 'development';

export enum TemplateFieldFieldName {
  fieldName = 'fieldName',
  labelName = 'labelName',
  fieldType = 'fieldType',
}

export type TemplateField = {
  name: string;
  id: string;
  weight: number;
  fields: {
    [TemplateFieldFieldName.fieldName]: string;
    [TemplateFieldFieldName.labelName]: string;
    [TemplateFieldFieldName.fieldType]: FieldTypes;
  };
};

interface TemplateFormProps {
  onSubmit(template: TemplateDto): void;
  templateFields?: TemplateField[];
  fixedValues?: {
    productCode?: string;
    productName?: string;
    siteType?: string;
  };
  templateType?: string;
  isEdit?: boolean;
}

type CustomFieldInputWithWeight = CustomFieldInput & { weight: number };

export const TemplateForm = ({
  onSubmit,
  templateFields,
  fixedValues,
  templateType,
  isEdit,
}: TemplateFormProps): ReactElement => {
  
  const [templateFieldsData, setTemplateFieldsData] = 
    useState<TemplateField[] | undefined>(templateFields);

  const { siteType, productCode, productName } = { ...fixedValues };

  const {
    control,
    register,
    handleSubmit: onFormSubmit,
    formState: { errors },
    getValues,
    unregister,
    setValue,
    watch,
    trigger,
  } = useForm();

  const handleRadioButtonChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const radioButtonValue = event.target.value as 'site' | 'asset';
    setValue('templateType', radioButtonValue);
  };

  const handleSubmit: SubmitHandler<Record<string, string>> = async (
    formValues
  ) => {
    const {
      templateType,
      siteType,
      productCode,
      productName,
      ...intialFields
    } = formValues;

    const weights: { [key in string]: { weight: number } } = {};
    const translations: { [key in string]: { en: string } } = {};

    const templateFields = (
      Object.values(intialFields) as unknown as CustomFieldInputWithWeight[]
    ).map(({ weight: currentWeight, ...rest }) => {
      weights[rest.fieldName] = { weight: currentWeight };
      translations[rest.fieldName] = { en: rest.labelName ?? '' };
      return rest;
    });

    if (!templateFields.length) {
      return;
    }

    onSubmit({
      fixedValues: {
        productCode,
        productName: productName ?? null,
        siteType,
      },
      type: templateType,
      templateString: createJsonSchema(templateFields),
      weights,
      translations,
    });
  };

  const handleFieldUpdate = (currentId: string) => {
    const { labelName, fieldType, fieldName } = getValues(currentId);

    trigger(currentId);
    setTemplateFieldsData((previousFields) => {
      const templateToModify = previousFields?.find(
        ({ id }) => id === currentId
      );

      if (!templateToModify) return previousFields;

      if (fieldName) {
        templateToModify.name = fieldName;
        templateToModify.fields.fieldName = fieldName;
      }

      if (labelName) templateToModify.fields.labelName = labelName;
      templateToModify.fields.fieldType = fieldType;

      return previousFields?.map((field) =>
        field.id !== currentId ? field : templateToModify
      );
    });
  };

  register('templateType', { value: templateType ?? 'asset' });
  const templateTypeValue = watch('templateType');

  return (
    <PageContainer.Content>
      {development && <DevTool control={control} placement="top-right" />}
      <Form className={styles.TemplateForm}>
        <BaseFieldsSection
          productCode={productCode}
          productName={productName}
          isEdit={isEdit}
          templateType={templateTypeValue ?? templateType}
          siteType={siteType}
          errors={errors}
          register={register}
          handleSubmit={handleSubmit}
          onFormSubmit={onFormSubmit}
          handleRadioButtonChange={handleRadioButtonChange}
        />
        <TemplateFormFields
          trigger={trigger}
          watch={watch}
          register={register}
          unregister={unregister}
          setValue={setValue}
          errors={errors}
          onFieldUpdate={handleFieldUpdate}
          templateFields={templateFieldsData}
          setTemplateFields={setTemplateFieldsData}
        />
      </Form>
    </PageContainer.Content>
  );
};
