import { ReactElement, ReactNode } from 'react';
import { Icon } from '@vaisala/rockhopper-components';
import {
  VaiColor,
  VaiIcon,
  VaiSpacing,
} from '@vaisala/rockhopper-design-tokens';

/* <Attention> renders important information. 
   It has four types ("success", "info", "warning", "failure"), 
   with corresponding icons and colors. 
   It also has two modes ("icon", where only the icon is rendered in the color; 
   and "box", where the icon and text are white against a colored background). */

export type AttentionProps = {
  children: ReactNode;
  mode?: 'icon' | 'box';
  type?: 'success' | 'info' | 'warning' | 'failure';
};

export const attentionConfigs = {
  success: {
    color: VaiColor.AlertOk,
    icon: VaiIcon.AlertOk,
  },
  info: {
    color: VaiColor.AlertInfo,
    icon: VaiIcon.AlertInfo,
  },
  warning: {
    color: VaiColor.AlertWarning,
    icon: VaiIcon.AlertWarning,
  },
  failure: {
    color: VaiColor.AlertAlarm,
    icon: VaiIcon.AlertAlarm,
  },
};

export const Attention = (props: AttentionProps): ReactElement => {
  const { children, mode = 'icon', type = 'failure' } = props;

  return mode === 'icon' ? (
    <>
      <Icon
        name={attentionConfigs[type].icon}
        style={{
          color: attentionConfigs[type].color,
          marginInlineEnd: VaiSpacing.Xs,
          fontSize: '1.25em',
        }}
        dataTa="AttentionComponentIcon"
      />
      {children}
    </>
  ) : (
    <span
      style={{
        backgroundColor: attentionConfigs[type].color,
        color: 'white',
        padding: VaiSpacing.Xs,
        display: 'block',
      }}
      data-ta="AttentionComponentIconContainer"
    >
      <Icon
        name={attentionConfigs[type].icon}
        style={{
          color: 'white',
          verticalAlign: 'text-bottom',
          marginInlineEnd: VaiSpacing.Xs,
          fontSize: '1.4em',
        }}
        dataTa="AttentionComponentIcon"
      />
      {children}
    </span>
  );
};
