import { Button } from '@vaisala/rockhopper-components';
import { ReactElement, useState } from 'react';
import { AssetPresentationDto } from '../../schema';
import {
  useGetQueueStatusQuery,
  useGetSAPExportStatusQuery,
  useStartSAPExportMutation,
} from '../../state/api/api';
import { useHasPermissionToEdit } from '../../utils/useUserPermissionHooks/useUserPermissionHooks';

import styles from './AssetSAPExport.module.scss';

export type AssetSAPExportProps = {
  id: AssetPresentationDto['id'];
};

export const AssetSAPExport = ({ id }: AssetSAPExportProps): ReactElement => {
  const {
    data: queueStatus,
    refetch: refetchQueueStatus,
    isError: isQueueStatusError,
  } = useGetQueueStatusQuery();
  // @todo: UPDATE TO DESTRUCT value from Record<string, string>
  const {
    data: sapExportStatus,
    refetch: refetchSAPExportStatus,
    isError: isSAPExportStatusError,
  } = useGetSAPExportStatusQuery(id);
  const [startExport] = useStartSAPExportMutation();
  const [isPolling, setIsPolling] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(
    !useHasPermissionToEdit()
  );
  const [pollingProgressValue, setPollingProgressValue] = useState(0);

  const handlePolling = () => {
    setIsPolling(true);

    setInterval(() => {
      setPollingProgressValue((prev) => {
        return prev >= 10 ? 0 : prev + 1;
      });
    }, 1000);
  };

  const handleRefetch = () => {
    refetchQueueStatus();
    refetchSAPExportStatus();
    setTimeout(handleRefetch, 10000);
  };

  const handleButtonClick = async () => {
    await startExport(id);

    setIsButtonDisabled(true);

    setTimeout(() => {
      setIsButtonDisabled(false);
      handlePolling();
      if (!isPolling) handleRefetch();
    }, 3000);
  };

  return (
    <section
      className={styles.AssetSAPExportContainer}
      data-ta="AssetSAPExport-container"
    >
      <section>
        <Button
          onClick={handleButtonClick}
          dataTa="AssetSAPExport-start-export-button"
          disabled={isButtonDisabled}
        >
          Start SAP Export
        </Button>
      </section>
      <section className={styles.AssetSAPExportStatus}>
        <progress
          className={styles.AssetSAPExportProgressBar}
          value={pollingProgressValue}
          max={10}
        ></progress>
        {!isSAPExportStatusError ? (
          <h2>
            Job state: <span>{sapExportStatus}</span>
          </h2>
        ) : null}
        {!isQueueStatusError ? (
          <p>
            Queued: <span>{queueStatus?.sapAssetUpdater}</span>
          </p>
        ) : null}
      </section>
    </section>
  );
};
