import { Icon } from '@vaisala/rockhopper-components';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { ReactElement } from 'react';
import { VisuallyHidden } from '../VisuallyHidden/VisuallyHidden';
import styles from './FieldListItem.module.scss';

interface ItemProps {
  itemId: string,
  itemName: string,
  itemIndex: number,
  isSelected: boolean,
  itemsCount: number,
  handleRadioButtonChange: (currentId: string) => void,
  handleDelete: (currentId: string) => void,
  handleFieldWeightChange: (currentId: string, direction: 'up' | 'down') => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: { [x: string]: any };
}

export const FieldListItem = ({
  itemId: id,
  itemName: name,
  itemIndex: index,
  isSelected, // true if this list item is selected (checked)
  itemsCount, // length of the items list
  handleRadioButtonChange,
  handleDelete,
  handleFieldWeightChange,
  errors = {},
}: ItemProps): ReactElement => {
  return (
    <li
      className={styles.ListItem}
      key={id}
      data-ta="TemplateFormFieldsListItem"
    >
      <input
        type="radio"
        name="TemplateFieldRadioElement"
        value={name}
        id={id}
        onChange={() => handleRadioButtonChange(id)}
        checked={isSelected}
        data-ta="TemplateFieldRadioElement"
      />
      <div className={isSelected ? styles.SelectedItem : ''}>
        <label
          htmlFor={id}
          data-ta="TemplateFormFieldsFieldLabel"
        >
          {Object.keys(errors).includes(id) && (
            <Icon
              name={VaiIcon.AlertWarning}
              color={VaiColor.AlertError}
            />
          )}
          {name}
        </label>
        <button
          onClick={() => handleDelete(id)}
          className={styles.ActionButton}
          data-ta="TemplateFormFieldsFieldDeleteButton"
        >
          <Icon name={VaiIcon.Trash} />
          <VisuallyHidden>Delete</VisuallyHidden>
        </button>
        <button
          onClick={() => handleFieldWeightChange(id, 'up')}
          disabled={index === 0}
          className={styles.ActionButton}
          data-ta="TemplateFormFieldsFieldUpButton"
        >
          <Icon name={VaiIcon.ArrowUp} />
          <VisuallyHidden>Move field up</VisuallyHidden>
        </button>
        <button
          onClick={() => handleFieldWeightChange(id, 'down')}
          disabled={index === itemsCount - 1}
          className={styles.ActionButton}
          data-ta="TemplateFormFieldsFieldDownButton"
        >
          <Icon name={VaiIcon.ArrowDown} />
          <VisuallyHidden>Move field down</VisuallyHidden>
        </button>
      </div>
    </li>
  );
}